.footer{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    width: 100%;
    z-index: 9;
}
.nowplayingbar{
    display: grid;
    grid-template-columns: 30% 40% 30%;
    background-color: var(--card-background);
    border-top: 1px solid var(--sidenav-active);
    padding: 17px 16px;
    color: #fff;
}
.footerMid{
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media screen and (max-width: 640px) {
    .footer{
        bottom: 45px;
    }
    
    .nowplayingbar{
        padding: 8px 16px;
        grid-template-columns: 1fr;
        row-gap: 0px;
    }
}