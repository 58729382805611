.playlistDetails{
    padding: 24px 32px 24px;
    display: grid;
    grid-template-columns: 232px 1fr;
    column-gap: 24px;
}
.imgBox{
    width: 232px;
    height: 232px;
}
.imgBox img{
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 4px 60px rgb(0 0 0 / 50%);
    box-shadow: 0 4px 60px rgb(0 0 0 / 50%);
}
.textBox{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.textBox h3{
    margin: 0;
    margin-top: 4px;
    letter-spacing: -0.4px;
}
.textBox h1{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
    padding: 0.08em 0px;
    font-size: 96px;
    line-height: 96px;
    font-weight: 700;
    letter-spacing: -.04em;
    text-transform: none;
}
.Artist{
    display: flex;
    align-items: center;
    margin-top: 16px;
}
.Artist figure{
    width: 24px;
    height: 24px;
    margin: 0;
    display: inline-block;
}
.Artist figure img{
    border-radius: 50%;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.Artist p{
    margin: 0;
    margin-left: 6px;
}
@media screen and (max-width: 640px) {
    .playlistDetails{
        grid-template-columns: 1fr;
        row-gap: 8px;
        place-items: center;
        text-align: center;
    }
    .imgBox{
        width: 125px;
        height: 125px;
    }
    .textBox h1{
        font-size: 32px;
        line-height: 32px;
    }
    .Artist{
        justify-content: center;
    }
}