.LibPage{
    background-color: #121212;
    color: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
}
.Library{
    padding: 3px;
    margin-bottom: 95px;
}
.Library h2 {
    margin: 0;
}
.Grid{
    margin-top: 24px;
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fill,minmax(var(--cards-column-width),1fr));
}
@media screen and (max-width: 640px) {
    .Library{
        
        margin-bottom: 120px;
        margin-top: 10px;
        position: fixed;
    }
    .Grid{
        grid-gap: 5px;
        grid-template-columns: 1fr 1fr 1fr;
    }
}